import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner, Row, Stack, Table, Button, Modal, Alert } from 'react-bootstrap';
import { fetchSkillOverview, fetchSkillList, deleteSkill } from '../../../firebase-config';

/**
 * スキル詳細ページ.
 */
function SkillDetailPage() {
    const location = useLocation();
    const { employee, myEmployee, date, customName } = location.state || {};
    const [overview, setOverview] = useState(null);
    const [skillList, setSkillList] = useState(null);
    const [deleteShow, setDeleteShow] = useState(false);

    const [showOverviewSuccess, setShowOverviewSuccess] = useState(false);
    const [showOverviewError, setOverviewShowError] = useState(false);
    const [overviewLoading, setOverviewLoading] = useState(false);

    const [showSkillListSuccess, setShowSkillListSuccess] = useState(false);
    const [showSkillListError, setSkillListShowError] = useState(false);
    const [skillListLoading, setSkillListLoading] = useState(false);

    const [showSkillDeleteError, setSkillDeleteError] = useState(false);

    const handleDeleteClose = () => setDeleteShow(false);
    const handleDeleteShow = () => setDeleteShow(true);

    const navigate = useNavigate();

    /**
     * スキル概要の取得.
     */
    const getOverview = useCallback(async () => {
        setOverviewLoading(true);

        try {
            const overviewData = await fetchSkillOverview(employee.id);
            setOverviewLoading(false);
            if (overviewData) {
                setOverview(overviewData);
                setShowOverviewSuccess(true);
                setOverviewShowError(false);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setOverviewLoading(false);
            setShowOverviewSuccess(false);
            setOverviewShowError(true);
        }
    }, [employee.id]);

    /**
     * スキルリストの取得.
     */
    const getSkillList = useCallback(async () => {
        setSkillListLoading(true);

        try {
            const skillList = await fetchSkillList(employee.id);
            setSkillListLoading(false);

            setSkillList(skillList);
            setShowSkillListSuccess(true);
            setSkillListShowError(false);
        } catch (error) {
            setSkillListLoading(false);
            setShowSkillListSuccess(false);
            setSkillListShowError(true);
        }
    }, [employee.id]);

    useEffect(() => {
        if (employee) {
            document.title = `${employee.lastName}${employee.firstName} - スキルシート`;
        }
        getOverview();
        getSkillList();
    }, [employee, getOverview, getSkillList]);

    /**
     * 誕生日のDate型から現在の年齢を求める.
     * 
     * @param {Date} date 
     * @returns {number} 現在の年齢
     */
    function getAge(date) {
        const birthday = new Date(date * 1000);

        const today = new Date();
        // 今年の誕生日
        const thisYearsBirthday = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());
        // 年齢
        var age = today.getFullYear() - birthday.getFullYear();
        if (today < thisYearsBirthday) {
            //今年まだ誕生日が来ていない
            age--;
        }
        return age;
    }

    /**
     * 改行コードをdiv区切りで変換.
     * 
     * @param {string} str 
     * @returns 改行された文字列
     */
    function convertBr(str) {
        return str.split("\n").map((line, index) => {
            return line === "" ? <div key={index}>&nbsp;</div> : <div key={index}>{line}</div>;
        });
    }

    /**
     * 年月のみ返却.
     * 
     * @param {date} date 
     * @returns {string} 年月
     */
    function convertYearMonth(date) {
        const time = new Date(date * 1000);
        return time.getFullYear() + "年" + (time.getMonth() + 1) + "月";
    }

    /**
     * 配列をliタグ区切りに変換.
     * 
     * @param {Array} array 
     * @returns liタグ区切りのhtml
     */
    function convertLi(array) {
        return array.map((reptile, index) => <li key={index}>{reptile}</li>);
    }

    /**
     * ハイフン区切りの日付を年月日に変換.
     * 
     * @param {date} date 
     * @returns 年月日の日付
     */
    function convertHihunDate(date) {
        const time = new Date(date);
        return time.getFullYear() + "年" + (time.getMonth() + 1) + "月" + time.getDate() + "日";
    }

    /**
     * 開始日から終了日までの期間を求める.
     * 
     * @param {number} start 
     * @param {number} end 
     * @returns 期間
     */
    function calculatePeriod(start, end) {
        const startDate = new Date(start * 1000); // 開始日
        const endDate = new Date(end * 1000); // 終了日
        // 完全な月の差を計算
        let months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
        // 終了日が開始日よりも後の場合は、1ヶ月を加える
        if (endDate.getDate() >= startDate.getDate()) {
            months += 1;
        }
        // 年と月に分ける
        const years = Math.floor(months / 12);
        months %= 12;
        // フォーマットして返す
        let result = '';
        if (years > 0) result += `${years}年`;
        if (months > 0) result += `${months}ヶ月`;
        return result;
    }

    /**
     * 経歴概要編集.
     * 
     * @param {employee} 従業員情報
     * @param {overview} 経歴概要
     */
    const handleOverviewEditClick = (employee, overview) => {
        navigate(`/skill/edit/overview`, { state: { employee, overview } });
    };

    /**
     * 経歴登録.
     * 
     * @param {employee} 経歴
     */
    const handleSkillRegisterClick = (employee) => {
        navigate(`/skill/edit/`, { state: { employee } });
    };

    /**
     * 経歴編集.
     * 
     * @param {employee} 従業員情報 
     * @param {skill} 経歴
     */
    const handleSkillEditClick = (employee, skill) => {
        navigate(`/skill/edit/`, { state: { employee, skill } });
    };

    /**
     * プリントアウト.
     */
    const handlePrint = () => {
        window.print();
    };

    /**
     * 経歴削除.
     * 
     * @param {string} skillId 
     */
    const handleSkillDeleteClick = async (skillId) => {
        setSkillDeleteError(false);
        try {
            await deleteSkill(skillId);
            setDeleteShow(false);
            window.location.reload();
        } catch (error) {
            setSkillDeleteError(true);
        }
    };

    return (
        <div style={{ fontSize: '80%' }}>
            <h2 className='text-center'>スキルシート</h2>

            {overviewLoading &&
                <div className="d-flex align-items-centerjustify-content-center justify-content-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            }

            <Stack direction="horizontal">
                <div className="ms-auto">{convertHihunDate(date)}</div>
            </Stack>
            <Stack direction="horizontal">
                <div className="ms-auto">
                    {customName ? (
                        customName
                    ) : (
                        <>{employee.lastName} {employee.firstName}</>
                    )}
                </div>
            </Stack>

            <div className='text-center mb-5 gone'>
                <Button onClick={() => handlePrint()} style={{ cursor: 'pointer' }} variant="light">
                    印刷
                </Button>
            </div>

            <Row className='mt-3'>

                <Table bordered>

                    <tbody>
                        {(myEmployee.role == 1 || myEmployee.id == employee.id) && (
                            <tr>
                                <td className='td-header' width={120}>年齢</td>

                                <td>

                                    {getAge(employee.birthday._seconds)}歳
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td className='td-header'>概要</td>
                            <td>
                                {showOverviewSuccess && convertBr(overview.description)}
                            </td>
                        </tr>

                        <tr>
                            <td className='td-header'>最寄り駅</td>
                            <td>{employee.line} {employee.station}</td>
                        </tr>

                        <tr>
                            <td className='td-header'>言語</td>
                            <td>
                                <ul>
                                    {showOverviewSuccess && convertLi(overview.language)}
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td className='td-header'>スキル</td>
                            <td>
                                <ul>
                                    {showOverviewSuccess && convertLi(overview.skill)}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {showOverviewError && <Alert variant="danger">スキル概要の取得に失敗しました。</Alert>}

                {myEmployee.role != 3 && (
                    <div className='text-center mb-5 gone'>
                        <Button onClick={() => handleOverviewEditClick(employee, overview)} style={{ cursor: 'pointer' }} variant="light">
                            編集
                        </Button>
                    </div>
                )}

                <div className='break_page'></div>

                <h2 className='text-center'>プロジェクト経歴</h2>
                {skillListLoading && <div className="d-flex align-items-centerjustify-content-center justify-content-center"><Spinner animation="border" variant="dark" /></div>}

                {myEmployee.role != 3 && (
                    <div className='text-center mb-5 gone'>
                        <Button onClick={() => handleSkillRegisterClick(employee)} style={{ cursor: 'pointer' }} variant="light">
                            経歴登録
                        </Button>
                    </div>
                )}

                <Row className='mt-3'>

                    <Table bordered>

                        <tbody>
                            <tr>
                                <th className='td-header' width={40}>No</th>
                                <th className='td-header' width={90}>期間</th>
                                <th className='td-header'>プロジェクト</th>
                                <th className='td-header' width={200}>詳細</th>
                                {myEmployee.role != 3 && (
                                    <th className='td-header gone' width={100}>編集/削除</th>
                                )}
                            </tr>
                        </tbody>


                        <tbody>
                            {showSkillListSuccess && skillList.length > 0 ? (
                                skillList
                                    .filter((skill) => skill.visible)
                                    .map((skill, index) => (
                                        <tr key={skill.id || index}>
                                            <td className={skill.highlight ? "skill_highlight" : "skill_normal"}>{index + 1}</td>

                                            <td className={skill.highlight ? "skill_highlight" : "skill_normal"}>
                                                {convertYearMonth(skill.start['_seconds'])}<br />
                                                〜<br />
                                                {skill.end !== "" && convertYearMonth(skill.end['_seconds'])}<br />
                                                {/* 期間 */}
                                                {calculatePeriod(
                                                    skill.start['_seconds'],
                                                    skill.end !== "" ? skill.end['_seconds'] : new Date().getTime() / 1000,
                                                )}
                                                <p className='gone'>{skill.company}</p>
                                            </td>

                                            <td className={skill.highlight ? "skill_highlight" : "skill_normal"}>
                                                <h6>{skill.overview}</h6>
                                                {convertBr(skill.detail)}
                                            </td>

                                            <td className={skill.highlight ? "skill_highlight" : "skill_normal"}>
                                                {skill.technology && (
                                                    <div>
                                                        <strong>技術</strong><br />
                                                        {skill.technology}
                                                    </div>
                                                )}

                                                {skill.lang && (
                                                    <div>
                                                        <strong>言語</strong><br />
                                                        {skill.lang}
                                                    </div>
                                                )}

                                                {skill.role && (
                                                    <div>
                                                        <strong>役割</strong><br />
                                                        {skill.role}
                                                    </div>
                                                )}

                                                {skill.development_count > 0 && (
                                                    <div>
                                                        <strong>開発者人数</strong><br />
                                                        {skill.development_count}人
                                                    </div>
                                                )}

                                                {skill.total_count > 0 && (
                                                    <div>
                                                        <strong>プロジェクト全体人数</strong><br />
                                                        {skill.total_count}人
                                                    </div>
                                                )}
                                            </td>

                                            {myEmployee.role != 3 && (
                                                <td className='gone'>
                                                    <Button
                                                        onClick={() => handleSkillEditClick(employee, skill)}
                                                        style={{ cursor: 'pointer' }}
                                                        variant="primary"
                                                        className='mb-3'
                                                    >
                                                        編集
                                                    </Button>
                                                    <Button
                                                        onClick={handleDeleteShow}
                                                        style={{ cursor: 'pointer' }}
                                                        variant="danger"
                                                    >
                                                        削除
                                                    </Button>

                                                    <Modal show={deleteShow} onHide={handleDeleteClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>{skill.overview}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>{showSkillDeleteError && 'スキルの削除に失敗しました'}</Modal.Body>
                                                        <Modal.Body>スキルを削除します。よろしいですか？<br />この動作は取り消すことができません。</Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleDeleteClose}>
                                                                キャンセル
                                                            </Button>
                                                            <Button variant="danger" onClick={() => handleSkillDeleteClick(skill.id)}>
                                                                削除
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </td>
                                            )}

                                        </tr >
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan="4">データが存在しません。</td>
                                </tr>
                            )}
                        </tbody>

                    </Table>
                </Row>

                {showSkillListSuccess && (
                    <Row className='gone'>
                        <h3>非表示経歴</h3>
                        <Table bordered>

                            <tbody>
                                <tr>
                                    <th className='td-header' width={40}>No</th>
                                    <th className='td-header'>プロジェクト</th>
                                    <th className='td-header gone' width={100}>編集/削除</th>
                                </tr>
                            </tbody>


                            <tbody>
                                {skillList
                                    .filter((skill) => !skill.visible)
                                    .map((skill, index) => (
                                        <tr key={skill.id || index}>
                                            <td className={skill.highlight ? "skill_highlight" : "skill_normal"}>{index + 1}</td>

                                            <td className={skill.highlight ? "skill_highlight" : "skill_normal"}>
                                                <h6>{skill.overview}</h6>
                                                {skill.detail}
                                            </td>

                                            <td className='gone'>
                                                <Button
                                                    onClick={() => handleSkillEditClick(employee, skill)}
                                                    style={{ cursor: 'pointer' }}
                                                    variant="primary"
                                                    className='mb-3'
                                                >
                                                    編集
                                                </Button>
                                                <Button
                                                    onClick={handleDeleteShow}
                                                    style={{ cursor: 'pointer' }}
                                                    variant="danger"
                                                >
                                                    削除
                                                </Button>

                                                <Modal show={deleteShow} onHide={handleDeleteClose}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>{skill.overview}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>{showSkillDeleteError && 'スキルの削除に失敗しました'}</Modal.Body>
                                                    <Modal.Body>スキルを削除します。よろしいですか？<br />この動作は取り消すことができません。</Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleDeleteClose}>
                                                            キャンセル
                                                        </Button>
                                                        <Button variant="danger" onClick={() => handleSkillDeleteClick(skill.id)}>
                                                            削除
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </td>

                                        </tr >
                                    ))
                                }
                            </tbody>

                        </Table>
                    </Row>
                )}
                {showSkillListError && <Alert variant="danger">スキルの取得に失敗しました。</Alert>}
            </Row >
        </div >
    );
}

export default SkillDetailPage;
